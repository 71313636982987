import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: "/brand",
    name: "brand",
    component: () => import("@/views/brand.vue")
  },
  {
    path: "/designer",
    name: "designer",
    component: () => import("@/views/designer.vue")
  },
  {
    path: "/service",
    name: "service",
    component: () => import("@/views/service.vue")
  },
  {
    path: "/case",
    name: "case",
    component: () => import("@/views/case.vue")
  },
  {
    path: "/caseDetail/:id",
    name: "caseDetail",
    component: () => import("@/views/caseDetail.vue")
  },
  {
    path: "/cats",
    name: "cats",
    component: () => import('@/views/cats.vue')
  },
  {
    path: "/goodsList/:id",
    name: "goodsList",
    component: () => import('@/views/goodsList.vue')
  },
  {
    path: "/goodDetail/:id",
    name: "goodDetail",
    component: () => import("@/views/goodDetail.vue")
  },
  {
    path: "/store",
    name: "store",
    component: () => import("@/views/store.vue")
  },
  {
    path: "/about/:id",
    name: "about",
    component: () => import('@/views/about.vue')
  },
  {
    path: "/storeDetail/:id",
    name: "storeDetail",
    component: () => import('@/views/storeDetail.vue')
  },
  {
    path: "/search/:name",
    name: "search",
    component: () => import("@/views/search.vue")
  }
]


const router = new VueRouter({
  routes,
  mode:"hash"
})


export default router
router.afterEach(() => {
  window.scrollTo(0, 0);
})
