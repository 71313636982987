<template>
  <div class="head">
    <div class="top">
      <img src="@/static/image/logo.jpg">
      <ul>
        <router-link to="/">首页</router-link>
        <router-link to="/brand">品牌</router-link>
        <li class="cats" @click="route()">
          产品
          <div class="list" v-if="cats[0]">
            <div class="box">
              <ul class="left">
                <li class="iconfont" v-for="(item, index) in cats" :key="item.id" @mousemove="changIndex(index)"
                  @click.stop="routerTo(item.id, item.big_pic_url, item.name)">
                  {{ item.name }}</li>
              </ul>
              <img :src="cats[cur].pic_url">
            </div>
          </div>
        </li>
        <router-link to="/store">门店</router-link>
        <router-link to="/case">案例</router-link>
        <router-link to="/service">服务</router-link>
        <li @click="show = !show">品牌商城</li>
      </ul>
      <div>
        <span class="icon iconfont icon-sousuo"></span>
        <el-input v-model="search" @keyup.enter.native="searchTo(search)"></el-input>
      </div>
    </div>
    <div class="bottom" :style="[{ 'max-height': show ? '9999px' : '0px' }]">
      <div>
        <img :src="setting.wechat_user_qr">
        <span>杜斯直营商城</span>
      </div>
    </div>
    <div class="icon iconfont icon-xinzengdaohangliebiao" @click="showNav = true"></div>
    <img class="navImg" src="@/static/image/logo.jpg">
    <div class="nav am-offcanvas" v-show="showNav" id="nav_top" @click="showNav = false">

      <ul id="collapase-nav" :style="[{ 'transform': showNav ? ' translateX(0%)' : 'translateX(100%)' }]">
        <img src="@/static/image/logo2.png">
        <li class="am-panel">
          <router-link to="/">首页</router-link>
        </li>
        <li class="am-panel">
          <router-link to="/brand">品牌</router-link>
        </li>
        <li class="am-panel">
          <router-link to="/cats">产品</router-link>
          <span class="am-icon-angle-right icon iconfont icon-xiangyou1"
            :style="[{ 'transform': showCats ? 'rotateZ(91deg)' : '' }]" @click.stop="showCats = !showCats"></span>
          <ul class="am-collapse" id="nav_3" v-if="showCats">
            <li @click="routerTo(item.id, item.big_pic_url, item.name)" v-for="item in cats" :key="item.id">
              <a href="javascript:0"> {{ item.name }}</a>
            </li>
          </ul>
        </li>
        <li class="am-panel">
          <router-link to="/store">门店</router-link>
        </li>
        <li class="am-panel">
          <router-link to="/case">案例</router-link>
        </li>
        <li class="am-panel">
          <router-link to="/service">服务</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: "heads",
  data() {
    return {
      show: false,//显示
      search: "",//搜索
      cur: 0,//图片
      showNav: false,//显示测栏
      showCats: false,//显示商品栏
    }
  },
  computed: {
    ...mapState(['cats', 'setting'])
  },
  methods: {
    route() {
      this.$router.push({ name: 'cats' })
    },
    routerTo(id, pic, name) {
      sessionStorage.setItem('pic', pic)
      sessionStorage.setItem('name', name)
      this.showCats = true
      this.$router.push({ name: "goodsList", params: { id } })
    },
    searchTo(name) {
      this.$router.push({ name: "search", params: { name } })
    },
    changIndex(index) {
      this.cur = index
    }
  },
}
</script>

<style lang="scss" scoped>
.icon-xinzengdaohangliebiao {
  padding: 10px 5px;
  font-size: 30px;
  display: none;


  @media screen and (max-width:600px) {
    display: block;
  }
}

.navImg {
  display: inline-block;
  margin: auto;
  position: absolute;
  z-index: 9;
  top: 7px;
  left: 50%;
  transform: translate(-50%);
  display: none;

  @media screen and (max-width:600px) {
    display: block;
  }
}

.nav {
  position: fixed;
  top: 0;
  z-index: 11;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;

  img {
    width: 40%;
    display: block;
    margin: 10px auto;
  }

  #collapase-nav {
    background: #333;
    display: block;
    height: 100%;
    width: 60%;
    overflow-y: auto;
    transition: 0.3s;

    a {
      color: #fff;
      margin-left: 10px;
    }
  }
}

.am-panel {
  line-height: 3em;
  border-bottom: 1px solid #444;
}

.am-icon-angle-right {
  float: right;
  margin-right: 10px;
  color: #fff;
  font-weight: bold;
}

.am-collapse {
  transition: 0.3s;
  li{
    margin-left: 10px;
  }
}

::v-deep.head {
  background: #fff;
  border-bottom: 1px solid #ccc;
  position: relative;

  >.top {
    max-width: var(--maxWidth);
    padding: 20px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;

    @media screen and (max-width:600px) {
      display: none;
    }

    >img {
      width: 100px;
    }

    >ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      a {
        margin: 0 20px;

        &:hover {
          font-weight: bold;
        }
      }

      li {
        margin: 0 20px;
        cursor: pointer;

        &:hover {
          font-weight: bold;
        }
      }

      .cats {
        &:hover {

          .list {
            transition: 0.3s;
            opacity: 1;
            max-height: 9999px;
          }
        }

        .list {
          position: absolute;
          max-height: 0;
          opacity: 0;

          cursor: auto;
          transition: 0.3s;
          overflow: hidden;
          font-weight: normal;
          width: 100%;
          margin-top: 10px;
          left: 0px;
          z-index: 2;
          color: #ccc;
          background: rgba($color: #000000, $alpha: 0.9);
          
          .box {
            padding: 30px;
            display: flex;
            justify-content: space-between;
            >img {
              width: 50%;

            }

            .left {
              transition: 0.3s;
              line-height: 2em;

              li {
                font-family: "微软雅黑";
                position: relative;

                &:hover {
                  transition: 1s;
                  color: #fff;

                  &::before {
                    position: absolute;
                    content: "";
                    background: #fff;
                    width: 2px;
                    top: 50%;
                    left: -10px;
                    transition: 1s;
                    transform: translateY(-50%);
                    height: 50%;
                  }

                  &::after {
                    content: "\e629";
                    left: 120%;
                    opacity: 1;
                    transition: 1s;
                  }
                }

                &::after {
                  content: "\e629";
                  position: absolute;
                  left: 0;
                  opacity: 0;
                  transition: 1s;
                }
              }
            }
          }

        }
      }
    }

    >div {
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-size: 30px;
        color: #999;
        margin-right: 5px;
      }

      .el-input__inner {
        border: 0;
        border-bottom: 1px solid #ccc;
      }
    }
  }

  .bottom {
    overflow: hidden;
    transition: 0.3s;
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    z-index: 2;
    background: #fff;

    >div {
      display: flex;
      padding: 20px 0;
      color: #999;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      >img {
        width: 100px;
        margin-bottom: 10px;
      }
    }
  }
}
</style>