import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import { request } from './tools/request';
import api from './tools/apiUrl';
import 'element-ui/lib/theme-chalk/index.css';
import "wowjs/css/libs/animate.css"

Vue.config.productionTip = false
Vue.use(ElementUI);

Vue.prototype.$request = request
Vue.prototype.$api = api

import { WOW } from "wowjs"
Vue.prototype.$wow = WOW
// Vue.prototype.$wow = new WOW({
//   boxClass: 'wow',
//   animateClass: 'animated',
//   offset: 50,
//   mobile: true,
//   live: false,
//   // live为true时，控制台会提示：MutationObserver is not supported by your browser. & WOW.js cannot detect dom mutations, please call .sync() after loading new content.
//   callback: function (box) {
//     console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
//   }
// })

new Vue({
  router,
  store,
  render: h => h(App),
  // mounted () {
  //   document.dispatchEvent(new Event('custom-render-trigger'))
  // }
}).$mount('#app')
