const apiUrl = {
  index:{
    config:"api/index/config",//主页
  },
  banner:{
    list:"api/mall-banner/list",//轮播图
  },
  cat:{
    list:"api/cat/list",//列表
    //api/cart/add-list
    homeList:"/api/cat/home-list"
    //https://tjd.wawall.net/web/index.php?_mall_id=2&r=/api/cat/home-list
  },
  cases:{
    showHome:"api/cases/show-home",//首页展示数据
    //https://tjd.wawall.net/web/index.php?_mall_id=2&r=api/cases/show-home
    index:"api/cases/index&region=%5B%5D"//案例中心
  },
  cart:{
    list:"api/cart/list",//获取采购单（购物车）
    delete:"api/cart/delete",//删除购物车
    add:"api/cart/add",//加入购物车
    //api/cart/add
    addList:"api/cart/add-list",//批量加入购物车
  },
  //api/cart/delete
  default:{
    district:"api/default/district",//城市id
    articleList:"api/default/article-list",//文章列表
    article:"api/default/article",//文章
    goodsList:"api/default/goods-list",//分类列表详细数据
    //api/default/goods-list&page=1&cat_id=7
    
    //api/default/goods-list&keyword=dg84216&mch_id=0&page=1
  },
  designer:{
    list:"api/designer/list"//设计师
  },
  goods:{
    detail:"api/goods/detail&id=",
    //api/goods/detail&id=89
    hotSearch:"api/goods/hot-search",
    ///api/goods/hot-search

    //api/goods/new-recommend&goods_id=93&type=goods
    saveBrowse:"api/goods/save-browse",//api/goods/save-browse&id=1&goodsId=1  统计用户浏览商品记录
  },
  
  passport:{
    login:"api/passport/login",//登录
    register:'api&r=api/passport/register'//注册
  },
  user:{
    address:"api/user/address",
    addressSave:'api/user/address-save',
    addressDestroy:"api/user/address-destroy",
    addressDefault:"api/user/address-default",
    favoriteAdd:'api/user/favorite-add',//收藏
    //api/user/favorite-add&goods_id=159
    favoriteRemove:'api/user/favorite-remove',//取消收藏
    //api/user/favorite-remove&goods_id=159
    myNewFavoriteGoods:'api/user/my-new-favorite-goods',//我的收藏
    //pi/user/my-new-favorite-goods
    userInfo:'api/user/user-info',
    //https://tjd.wawall.net/web/index.php?_mall_id=2&r=api/user/user-info//我的信息页面
    userSave:"api/user/user-save",
    //api/user/user-save
    logout:"/api/user/logout"
    ///api/user/logout
  },
  order:{
    preview:"api/order/preview",
    //api/order/preview
    submit:"api/order/submit",//购买支付方式
    //api/order/submit
    payData:"api/order/pay-data",
    //api/order/pay-data
    getPlan:"api/order/get-plan",
    //api/order/get-plan
    list:"api/order/list",
    //api/order/list&status=0&keyword=&dateArr=%5B%5D&page=1
    orderExportTable:"api/order/order-export-table",
    ///api/order/order-export-table&id=47
    detail:"api/order/detail",
    //api/order/detail
    cancel:"api/order/cancel"
    //api/order/cancel&id=7
  },
  payment:{
    payBuy:"api/payment/pay-buy",
    getPayments:"api/payment/get-payments",
    payData:"api/payment/pay-data",
    
    //api/payment/get-payments    
  },
  video:{
    index:'api/video/index&page=1'
  },
  topic:{
    list:"api/topic/list",//api/topic/list&page=1&type=0 专辑列表
    detail:"api/topic/detail&id="//api/topic/detail&id=5
  },
  store:{
    list:"api/store/list&longitude=1&latitude=1",//门店列表
    detail:"api/store/detail&id="//门店详情
  }

}
export default apiUrl
