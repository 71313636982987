import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    designer: [],//设计师
    cases: [],//案例
    cats: [],//商城分类
    setting: {}//商城内容
  },
  mutations: {
    changeDesigner(state, data) {
      state.designer = data
    },
    changeSetting(state, data) {
      state.setting = data
    },
    changeCase(state, data) {
      state.cases = data
    },
    changeCats(state, data) {
      state.cats = data
    }

  },
  actions: {
  },
})
