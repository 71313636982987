import axios from "axios";
import { Message } from "element-ui";

const token = () => {
  return sessionStorage.getItem('token') || ''
}

if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'api'
} else {
  axios.defaults.baseURL = "web/index.php?_mall_id=2&r="
}

export const service = axios.create({
  timeout: 10000
})

service.interceptors.request.use(config => {
  config.headers['x-access-token'] = token()
  return config
})
service.interceptors.response.use(res => {
  if (res.data.code == 1) {
    Message({
      message: res.data.res,
      type: 'error'
    })
  }
  return res.data
}, err => {
  const msg = err.Message != undefined ? err.Message : ""
  Message({
    message: "网络错误" + msg,
    type: "error",
    duration: 3 * 1000
  })
  return Promise.reject(err)
})