<template>
  <div id="app">

    <Head></Head>
    <router-view></router-view>
    <Foot></Foot>
  </div>
</template>

<script>
import Head from './components/head/head.vue';
import Foot from './components/foot/foot.vue';
import { mapMutations } from 'vuex';
export default {
  name: "app",
  components: { Head, Foot },
  methods: {
    ...mapMutations(['changeDesigner', 'changeCase', 'changeCats', 'changeSetting']),
    getDesigner() {
      this.$request({ url: this.$api.designer.list }).then(res => {
        if (res.code == 0) {
          this.changeDesigner(res.data.list)
        }
      })
    },
    getCase() {
      this.$request({ url: this.$api.cases.index + "&limit=9999" }).then(res => {
        if (res.code == 0) {
          this.changeCase(res.data.list)
        }
      })
    },
    getCats() {
      this.$request({ url: this.$api.cat.list }).then(res => {
        if (res.code == 0) {
          this.changeCats(res.data.list)
        }
      })
    },
    getConfig() {
      this.$request({ url: this.$api.index.config }).then(res => {
        if (res.code == 0) {
          //console.log(res.data.mall.setting);
          const data = res.data.mall.setting
          this.changeSetting(data)
        }
      })
    }
  },
  mounted() {
  },
  created() {
    this.getDesigner()
    this.getCase()
    this.getCats()
    this.getConfig()
  }
}
</script>

<style lang="scss">
@import url("@/static/css/iconfont.css");
@import url('swiper/css/swiper.min.css');

:root {
  --maxWidth: 1200px;
}

* {
  border: 0;
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  color: #000;
  text-decoration: none;
}
</style>
