import { service } from "./api";
import qs from"qs"

export function request(data){
  return service({
    url:data.url,
    data:qs.stringify(data) || '',
    method:data.method || 'GET',
    headers:{
      'Content-Type':'application/x-www-form-urlencoded',
    }
  })

}