<template>
  <div class="footer">
    <div class="box">
      <div class="left">
        <img src="@/static/image/footer/footerLogo.jpg">
        <p>联系我们：<br>{{ setting.contact_tel }}( 9:00 - 21:00 )</p>
        <div>
          <li>
            <img src="@/static/image/footer/icon-1.jpg">
            <img class="i1" :src="setting.mobile_qr">
          </li>
          <li>
            <img src="@/static/image/footer/icon-2.jpg">
            <img class="i1" :src="setting.wechat_qr">
          </li>
          <li>
            <img src="@/static/image/footer/icon-3.jpg">
            <img class="i1" :src="setting.weibo_qr">
          </li>
          <li>
            <img src="@/static/image/footer/icon-4.jpg">
            <img class="i1" :src="setting.trill_qr">
          </li>
          <li>
            <img src="@/static/image/footer/icon-5.jpg">
            <img class="i1" :src="setting.wechat_user_qr">
          </li>
        </div>
        <router-link to="/store">杜斯全国实体店 →</router-link>
      </div>
      <div class="right">
        <ul>
          <li>
            <span>关于杜斯</span>
            <router-link to="/brand">杜斯品牌</router-link>
            <router-link to="/designer">联名设计师</router-link>
            <router-link :to="'/about/' + item.id" v-for="item in about" :key="item.id">{{ item.title }}</router-link>
          </li>
          <li>
            <span>产品分类</span>
            <a href="javascript:0" @click="routerToList(item.id, item.big_pic_url, item.name)" v-for="item in cats"
              :key="item.id">{{ item.name }}</a>
          </li>
          <li>
            <span>服务支持</span>
            <router-link :to="'/about/' + item.id" v-for="item in fuwu" :key="item.id">{{ item.title }}</router-link>
            <router-link to="/service">物流售后服务</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="footer2">{{ setting.copyright }}</div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: "foot",
  data() {
    return {
      about: [],//关于杜斯
      fuwu: [],//服务支持
    }
  },
  computed: {
    ...mapState(['cats', 'setting'])
  },
  methods: {
    routerToList(id, pic, name) {
      this.$router.push({ name: "goodsList", params: { id } })
      sessionStorage.setItem('pic', pic)
      sessionStorage.setItem('name', name)
    },
    getAbout() {
      this.$request({ url: this.$api.topic.list + "&type=1" }).then(res => {
        if (res.code == 0) {
          this.about = res.data.list
        }
      })
    },
    getFuwu() {
      this.$request({ url: this.$api.topic.list + "&type=3" }).then(res => {
        if (res.code == 0) {
          this.fuwu = res.data.list
        }
      })
    }
  },
  created() {
    this.getAbout()
    this.getFuwu()
  }
}
</script>
<style lang="scss" scoped>
.footer {
  font-family: "微软雅黑";
  background: #000;
  color: #fff;


  .box {
    padding: 60px 50px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (max-width:600px) {
      flex-direction: column;
      padding: 20px 10px;
    }

    >.left {
      @media screen and (max-width:600px) {
        display: block;
      }
      >img {
        width: 100px;
        margin-bottom: 50px;
        @media screen and (max-width:600px) {
          margin-bottom: 20px;
        }
      }

      >p {
        font-size: 0.8em;
        line-height: 1.5em;
        display: block;
        margin-bottom: 15px;
      }

      >div {
        display: flex;
        justify-content: center;
        margin-bottom: 60px;
        @media screen and (max-width:600px) {
          margin-bottom:20px ;
        }
        li {
          width: 20%;
          position: relative;
          color: #fff;

          .i1 {
            position: absolute;
            left: 0;
            top: -135px;
            transition: 0.3s;
            width: 0;
            height: 100px;
            padding: 10px;
            opacity: 0;
            background: #fff;
          }

          &:hover {
            &::before {
              content: "";
              transition: 0.3s;
              position: absolute;
              left: 50%;
              top: -10px;
              transform: translateX(-50%);
              border-bottom: 10px solid transparent;
              width: 0;
              border-top: 10px solid #fff;
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
            }

            .i1 {
              width: 100px;
              height: 100px;
              transition: 0.3s;
              opacity: 1;
            }

          }


          >img {
            margin: 5px;
            cursor: pointer;
          }
        }

      }

      >a {
        border: 1px solid;
        background: #000;
        padding: 8px 30px;
        color: #fff;
      }
    }

    >.right {
      @media screen and (max-width:600px) {
        width: 100%;
      }

      >ul {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        >li {
          display: flex;
          flex-direction: column;
          text-align: right;
          width: 120px;

          @media screen and (max-width:600px) {
            width: auto;
            justify-content: space-between;
            margin-top: 50px;
            width: 100%;
            text-align: left;
          }

          span {
            margin-bottom: 40px;
            font-size: 20px;
            @media screen and (max-width:600px) {
              margin-bottom: 20px;
            }
          }

          a {
            color: #fff;
            line-height: 2em;
          }
        }
      }

    }

    
  }
  >.footer2 {
      text-align: center;
      line-height: 3;
      font-size: 10px;
    }
}
</style>